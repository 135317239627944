@import 'abstracts/variables';

body p-multiSelectItem li.p-multiselect-item div.p-checkbox {
  border: none !important;
}

body p-multiSelectItem li.p-multiselect-item div.p-checkbox div.p-checkbox-box,
body cap-checkbox div.form-element p-checkbox div.p-checkbox div.p-checkbox-box,
body .p-checkbox .p-checkbox-box {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: $white;
  box-shadow: inset 0 0 0 2px $white;

  &.p-state-active {
    background: var(--primary-color);

    &:hover {
      background: var(--primary-color);
    }

    & > span.p-checkbox-icon {
      display: none;
    }
  }
}

body cap-checkbox.checkbox-rounded div.form-element p-checkbox div.p-checkbox div.p-checkbox-box,
cap-table .p-table .p-table-wrapper table .p-table-tbody tr td.selectable-wrapper .p-checkbox .p-checkbox-box {
  border-radius: 20px;
  border-color: var(--success-color);
  background: $white;

  &.p-state-active {
    background: var(--success-color);
  }
}

cap-checkbox:focus, li.p-multiselect-item, p-checkbox {
  outline: none;
}

body .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon,
body .p-checkbox .p-checkbox-box.p-highlight.p-focus .p-checkbox-icon,
body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover .p-checkbox-icon,
body .p-checkbox .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover .p-checkbox-icon {
  font-size: 14px;
}
