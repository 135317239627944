@import 'abstracts/variables';
@import 'abstracts/breakpoints';

.ibox {
  position: relative;
  padding-top: 15px;

  .title h5, .ibox-title h5 {
    font-size: 20px;
    font-weight: 600;
    color: $muted-color;
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 35px;
    }
  }

  .ibox-content {
    @include media-breakpoint-down(sm) {
      overflow-x: scroll;
    }
  }

  .settings-menu-item {
    background: $white !important;
    color: $preview-color;
    border: none;
    padding: 30px 75px !important;
    box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.1);

    .p-button-icon-left {
      position: relative;
      left: 0;
      margin: 0 0 20px !important;
      padding: 0 !important;
      font-size: 50px;
      color: $icon-color;
    }

    .p-button-text {
      margin: 0 !important;
      padding: 0 !important;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .p-table-caption {
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
    display: inline-block;

    @include media-breakpoint-down(sm) {
      width: 100%;
      top: 50px;
    }

    .col-md-3 {
      flex: 100%;
      max-width: 100%;
    }
  }

  .p-table {
    .p-table-wrapper {
      .p-table-thead {
        .table-actions-col {
          width: 80px;
        }
      }
    }

    input {
      color: $muted-color;
    }
  }
}

body .p-selectbutton .p-button.p-state-disabled {
  background: #dddddd;
}

input[type='text'],
textarea {
  &:disabled {
    background: #dddddd;
  }
}
