// Override Bootstrap
// Note: since v5 ml-* and mr-* are now ms-* and me-*
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.float-right {
  float: right !important;
}

.form-group {
  margin-bottom: 1rem;
}

.text-right {
  text-align: right !important;
}

.row {
  > cap-button {
    width: auto;
  }
}