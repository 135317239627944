@media print {
  body {
    min-width: auto !important;
  }

  #printContainer {
    width: 99% !important;
  }

  #printContainer > .printedPage {
    width: 99% !important;
  }
}
