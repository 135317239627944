@import 'abstracts/variables';

.page__main.main {
  padding: 0 40px !important;
}

.config-card {
  i {
    font-size: 3em !important;
    color: #80afdb;
  }

  .title {
    color: #5c5c5c;
  }
}

.headline__title {
  margin-top: 1em;
  font-size: 20px !important;
  font-weight: 600;
  color: #969696;
}

h5.filters-label {
  color: #969696;
  font-size: 16px;
  font-weight: 600;
  line-height: 2em;
  float: left;
}

p-table .cap-table {
  margin-top: 2em;
}
