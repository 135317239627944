.p-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p-paginator {
  display: inline-block;
  width: 100%;

  .p-paginator-bottom {
    color: var(--text-color);
    display: flex;
  }

  anglelefticon,
  anglerighticon {
    display: none;
  }
}