@import 'abstracts/variables';

ul.check-list {
  list-style: none;
  padding: 10px 0 0;

  &:not(.no-border) {
    border-top: 1px solid $gray-light;
  }

  > li {
    margin-bottom: 5px;
    padding: 10px 15px;
    border-radius: 3px;
    border: 1px solid $border-color;

    cap-checkbox {
      &.checkbox-rounded {
        .form-element {
          border-bottom: 0;

          p-checkbox {
            .p-chkbox .p-chkbox-box.p-state-active {
              border-color: $success;
              background: $success;

              .p-chkbox-icon {
                top: 2px;
                left: 2.5px;

                &:before {
                  color: $success;
                }
              }
            }
          }
        }
      }
    }

    p-checkbox {
      display: flex;
      outline: none;

      .p-chkbox {
        margin-right: 10px;

        .p-chkbox-box {
          background: transparent;

          .p-chkbox-icon {
            font-size: 16px;
            margin-left: -1px;
          }

          &.p-state-active {
            border-color: var(--primary-color);
          }
        }
      }

      .p-chkbox-label {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
