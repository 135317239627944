@import 'abstracts/variables';
@import 'abstracts/breakpoints';

body .p-checkbox .p-checkbox-box.cap-state-active:not(.cap-state-disabled):hover {
  background: $primary;
}

table {
  width: 100%;
}

p-table tr.disabled-table-row td,
.cap-table tr.disabled-table-row td {
  cursor: not-allowed;
}

.cap-table, p-table, cap-data-table {
  &.clickable {
    .cap-table-tbody tr:hover td,
    .disabled-table-row td {
      cursor: pointer;
      background: #eee !important;
    }
  }

  .cap-table {
    position: relative;

    .cap-table-caption {
      background-color: inherit;
      border: none;

      &:empty {
        display: none;
      }

      .col-md-3 {
        padding: 0;

        .input-group-text {
          background-color: $white;
          border-right: none;
        }

        .form-control {
          padding-left: 0;
          border: 1px solid $table-border-transparent;
          border-left: none;

          &:focus {
            outline: 0;
            box-shadow: none;
            border: 1px solid $table-border-transparent;
            border-left: none;
          }
        }
      }
    }

    .p-datatable-wrapper {
      position: relative;

      table {
        border-collapse: separate;
        border-spacing: 0;

        .p-datatable-thead {
          tr {
            th {
              border: none;
              border-bottom: 1px solid $border-color;
              padding: 15px;
              background-color: inherit;
              position: relative;
              text-align: left;

              &:hover,
              &:focus {
                background-color: inherit;
                box-shadow: none;
                outline: none;
              }

              &.table-selectable-col {
                width: 50px;
              }

              span {
                font-size: $table-font-size;
                color: $muted-color;
              }

              &.cap-sortable-column.cap-state-highlight .cap-sortable-column-icon {
                color: #848484;
              }
            }
          }
        }

        .p-datatable-tbody {
          tr {

            &.disabled-table-row td {
              background: #eee;
            }

            td {
              border: none;
              background-color: $white;
              padding: 15px;

              &.actions-wrapper {
                i {
                  font-size: 16px;
                  cursor: pointer;

                  &:hover {
                    color: var(--primary-color);

                    &.fa-trash-alt {
                      color: var(--error-color);
                    }
                  }

                  &:not(:last-child) {
                    margin-right: 15px;
                  }
                }
              }

              &.list-page-table-column {
                .cap-column-title {
                  display: none;
                }
              }

              &.selectable-wrapper {
                width: 30px;
              }

              span {
                font-size: $table-font-size;
                color: $dropdown-text-color;
              }
            }
          }
        }
      }
    }

    .cap-table-loading-content {
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      align-items: center;
      background: white;
      display: flex;
      opacity: 0.5;
      z-index: 1;
      font-size: 20px;
      left: 0;
      justify-content: center;
      top: 0;
      margin: 0;
    }
  }

  [styleclass="p-paginator-bottom"] .p-paginator.p-component {
    border: none;
    background-color: inherit;
    text-align: right;
    float: right;
    width: 100%;
    padding: 15px 0;
    position: relative;
    color: $dark-gray;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    .p-paginator-left-content {
      display: flex;
      padding-top: 15px;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;

      @include media-breakpoint-down(sm) {
        position: relative;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 15px;
      }

      .form-element {
        height: 33px;
      }

      > span:first-child {
        margin-left: 0 !important;
      }

      .cap-dropdown {
        position: relative;
        height: 33px;
        margin-left: 0;

        .cap-helper-hidden-accessible {
          display: none;
        }

        .cap-dropdown-label {
          padding: 5px 26px 5px 8px;
          color: $dropdown-text-color;
          position: relative;
        }

        .cap-dropdown-trigger {
          border-left: 1px solid $border-color;
          background-color: transparent;
          width: 20px;
          font-size: 10px;
          color: #333;
        }

        .cap-dropdown-panel {
          border: none;
        }

        .cap-dropdown-items-wrapper {
          .cap-dropdown-items {
            list-style: none;
            background-color: $white;
            padding: 0;
            box-shadow: 0 5px 13px 0 $dropdown-shadow-color;
            border: none;

            p-dropdownitem {
              .cap-dropdown-item {
                padding: 5px 10px;
                cursor: pointer;

                &:hover {
                  background-color: $border-color;
                }
              }

              &:not(:last-child) {
                .cap-dropdown-item {
                  border-bottom: 1px solid $border-color;
                }
              }
            }
          }
        }
      }
    }

    .p-paginator-element {
      padding: 9px 7px;
      align-items: center;
      display: inline-flex;
      border: 1px solid $border-color;
      background-color: $white;
      font-size: 14px;
      font-weight: normal;

      &:last-child {
        border-right: 1px solid $border-color;
      }

      &.p-paginator-prev,
      &.p-paginator-next {
        anglelefticon,
        anglerighticon {
          display: none;
        }
      }

      &.p-highlight {
        background: var(--cap-primary-color);
        color: $white;
      }
    }

    .p-paginator-pages {
      .p-paginator-element {
        padding: 9px 13px;

        &:first-of-type {
          border-left: 1px solid $border-color;
        }

        &.p-state-active {
          color: $white;
        }

        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}

.filter-label {
  color: rgba(51, 51, 51, 0.5);
  font-size: 14px;
  font-weight: 600;
  line-height: 33px;
}

cap-card.campaign-table-card .card .card__content.content--gutter {
  margin-top: 0;
}

cap-data-table.campaign-table {
  p-table {
    margin-top: 0;
  }

  .cap-table .p-datatable-wrapper table tr,
  .cap-table .p-datatable-wrapper table tr {
    td {
      border-bottom: 1px solid $gray-light;
      border-top: 1px solid $gray-light;
      margin-bottom: 5px;

      .divider {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;

        .campaign-name {
          max-width: 90%;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    td:first-child {
      border-left: 1px solid $gray-light;
      border-radius: 3px;
    }

    td:last-child {
      border-right: 1px solid $gray-light;
      border-radius: 3px;
    }
  }
}

.clear-filter-icon {
  color: $danger;
}

.cap-table:not(.custom-table) .p-paginator:not(.custom-paginator) {
  .p-paginator-prev,
  .p-paginator-next {
    width: auto;
    min-width: auto;
    padding: 9px 12.2px;
  }

  .p-paginator-prev {

    &:before {
      position: relative;
      left: unset;
    }
  }
}

body .p-datatable .p-sortable-column {
  .p-sortable-column-icon {
    font-size: 12px;
    margin-left: 3px;
  }
}

body cap-info-table p-table .cap-info-table table {
  thead.p-datatable-thead tr th span.p-sortable-column:hover,
  thead.p-datatable-thead tr th span.p-sortable-column {
    color: $lighter-gray;
    background: none;
    font-size: 12px;
    font-weight: 400;

    cap-sort-icon i {
      &:before {
        color: $primary;
        font-family: 'primeicons';
        content: '\e99e';
        font-weight: 100;
        margin-left: 5px;
      }

      &.fa-chevron-down:before {
        content: '\e9a0';
      }

      &.fa-chevron-up:before {
        content: '\e9a2';
      }
    }
  }
}

.p-column-title {
  display: none;
  font-weight: bold;

  @media screen and (max-width: 960px) {
    display: block;
  }
}

.cap-info-table.p-datatable .p-paginator {
  overflow: unset;
}

.cap-table.p-datatable {
  spinnericon {
    .p-datatable-loading-icon.p-icon {
      width: 4rem;
      height: 4rem;
      color: var(--primary-color);
    }
  }

  .p-datatable-thead {
    .table-header {
      p-sorticon {
        .p-icon-wrapper {
          .p-sortable-column-icon {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

cap-table-filters {
  .cap-table-filters-wrapper {
    .cap-table-filter {
      position: relative;

      cap-input {
        &.has-icon {
          .form-element {
            .p-inputgroup {
              input {
                &.p-inputtext {
                  max-height: 30px;
                  min-height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

cap-info-table,
cap-data-table {
  p-tablecheckbox,
  p-tableheadercheckbox {
    .p-checkbox-box {
      &.p-highlight {
        .p-icon-wrapper {
          @include checkBoxIcon();
        }
      }
    }
  }
}
