.query-dialog-dropdown {
  min-width: unset;
}

cap-dropdown .form-element {
  width: 100%;
}

cap-dropdown .cap-dropdown-panel.p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon {
  top: 0.5em;
  right: unset;
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0;
}

cap-dropdown .cap-dropdown-panel.p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon {
  top: 1rem;
}

.cap-table.p-datatable cap-dropdown {
  .p-dropdown:not(.num-rows-dropdown) {
    &.client-entity-dropdown {
      max-width: 100px;
    }
  }
} 