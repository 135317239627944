@import 'abstracts/variables';
@import 'primeng/resources/components/toast/toast.css';

body {
  background-color: $body-background;
}

.clickable {
  cursor: pointer;
}

.container {
  background-color: #fff;
}

.content {
  padding: 10px 0;
  margin-bottom: 15px;

  .content-link {
    padding: 0 40px 10px;
  }

  .content-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px 10px;

    &:not(.no-border) {
      border-bottom: 1px solid $border-color;
      margin-bottom: 15px;
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
    }

    .content-header-left {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .content-body {
    padding: 0 40px;
  }
}

.column-heading {
  font-size: 16px;
  font-weight: 600;
  padding: 25px 0;
}

.vertical-divider {
  > div:not(:first-child) {
    .column-content {
      border-left: 1px solid $border-color;
      margin-left: -15px;
      margin-right: -15px;
      padding: 0 15px;
    }
  }
}

.horizontal-divider {
  > div:not(:first-child) {
    border-top: 1px solid $border-color;
  }
}

.bottom-divider {
  border-bottom: 1px solid $border-color;
}

.search-result-highlight {
  background: $search-result-highlight-color;
}

.override-col {
  min-width: initial;
}
