@layer primeng {
    .p-radiobutton {
        display: inline-flex;
        cursor: pointer;
        user-select: none;
        vertical-align: bottom;
        position: relative;
    }

    .p-radiobutton-box {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .p-radiobutton-icon {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform: translateZ(0) scale(0.1);
        border-radius: 50%;
        visibility: hidden;
    }

    .p-radiobutton-box.p-highlight .p-radiobutton-icon {
        transform: translateZ(0) scale(1, 1);
        visibility: visible;
    }

    p-radiobutton {
        display: inline-flex;
        vertical-align: bottom;
        align-items: center;
    }

    .p-radiobutton-label {
        line-height: 1;
    }
}
