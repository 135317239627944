@import 'primeng/resources/components/radiobutton/radiobutton.css';

p-radioButton {
  display: flex;
}

body .p-radiobutton.green .p-radiobutton-box.p-state-active:hover,
body .p-radiobutton.green .p-radiobutton-box.p-state-active, {
  border-color: $success;
  background-color: $white;
  padding: 0;

  .p-radiobutton-icon {
    background-color: $success;
    width: 14px;
    height: 14px;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -7px;
    margin-top: -7px;
    transition: background-color 0.2s;
    border-radius: 50%;
  }
}
