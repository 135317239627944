@import 'primeng/resources/components/dialog/dialog.css';
@import 'abstracts/variables';
@import 'abstracts/breakpoints';

body .p-dialog {
  box-shadow: 0 5px 13px 0 $dialog-shadow-color;
  border: none;
  width: 50%;

  &.p-confirmdialog {
    .p-dialog-titlebar {
      span {
        font-size: 24px;
      }

      a.p-dialog-titlebar-icon {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }

    .p-dialog-footer {
      .p-button {
        font-size: 16px;
        padding: 7px 25px 7px 15px;

        &.p-button-text-icon-left {
          .p-button-icon-left {
            margin-right: 10px;
            vertical-align: middle;
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  &.p-widget {
    padding: 10px 40px;
    min-width: 50%;

    @include media-breakpoint-up(xl) {
      min-width: 600px;
    }
    @include media-breakpoint-up(md) {
      min-width: 600px;
    }

    .p-dialog-titlebar, .p-dialog-content, .p-dialog-footer {
      border: none;
      overflow: unset;
    }

    .p-dialog-titlebar {
      background-color: $white;
      text-align: center;

      .p-dialog-title {
        font-size: 26px;
      }

      .p-dialog-titlebar-icon {
        display: none;
      }
    }
  }
}

.dialog-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}

.query-dialog .p-dialog-content {
  height: 95%;
}

.p-dialog.communication-confirm-dialog {
  .p-dialog-titlebar.p-widget-header {
    text-align: left;

    .p-dialog-titlebar-icon {
      position: absolute;
      top: 0;
      right: -2rem;
      text-align: left;
      z-index: 1;
      display: unset;

      span {
        font-size: 2rem;
      }
    }
  }
}
