@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
@import 'abstracts/variables';

body {
  font-family: 'Open Sans', sans-serif;
}

a,
a:not([href]):not([tabindex]) {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 14px;
  position: relative;

  &:hover {
    text-decoration: none;
    color: var(--primary-color-shadow);
  }

  &.has-icon {
    line-height: 10px;
    padding-left: 20px;

    i {
      font-size: 11px;
      margin-right: 10px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  &.underline {
    text-decoration: underline;
  }
}

p {
  &.full-center {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $border-color;
    line-height: 0.1em;
    margin: 25px 0 25px;

    span {
      background: $body-background;
      padding: 0 10px;
      color: $block-center-color;
    }
  }
}

.fs-16 {
  font-size: 16px;
}

.fw-600 {
  font-weight: 600 !important;
}

.text-lighter-grey {
  color: $lighter-gray;
}

.cap-form-field-label {
  color: $preview-color;
}

.cursor-pointer {
  cursor: pointer;
}

.wrap-words {
  word-wrap: break-word;
  margin-bottom: 0;
}
