@import 'abstracts/mixins';

$size: 5;

@while ($size <= 30) {
  @include all-directions(p, padding, $size, px);
  @include all-directions(m, margin, $size, px);

  $size: $size + 5;
}

$radius: 1;

@while ($radius < 10) {
  .br-#{$radius} {
    border-radius: #{$radius}px;
  }

  $radius: $radius + 1;
}
