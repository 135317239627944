@import 'abstracts/variables';

.cap-multi-select-panel .p-multiselect-filter-container {
  .p-multiselect-filter:focus {
    box-shadow: unset;
  }
}

body .cap-multi-select-panel,
body .p-multiselect {
  .p-multiselect-items-wrapper {
    .p-multiselect-items {
      .p-multiselect-item,
      .p-multiselect-item.p-highlight {
        .p-checkbox-box,
        .p-checkbox-box:hover,
        .p-checkbox-box.p-highlight,
        .p-checkbox-box.p-highlight:hover {
          height: 1rem;
          width: 1rem;

          .p-icon-wrapper {
            @include checkBoxIcon();
          }
        }
      }
    }
  }
}
