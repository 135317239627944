@import 'abstracts/variables';

.block {
  background-color: $white;
  border-radius: $btn-border-radius;
  padding: 15px 30px;

  &.block-active {
    border: 1px solid var(--primary-color);
  }

  .block-header {
    font-size: 12px;
    color: $primary;
  }

  .block-content {
    padding: 5px 0;
    font-size: 14px;
  }

  .block-footer {
    font-size: 12px;
    color: $block-footer-color;
  }
}
