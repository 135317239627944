@import '../abstracts/variables';
@import 'primeng/resources/components/calendar/calendar.css';

cap-calendar {
  p-calendar {
    outline: none;
  }

  .form-element {
    .p-calendar {
      display: flex;

      .p-inputtext {
        padding: 8px 10px;
        border-color: $border-color;
      }

      .p-datepicker-trigger {
        &.p-button {
          border-color: $border-color;
          background-color: $primary;
          color: $white;
          font-size: 17px;

          span.p-button-icon-left {
            font-size: 1.25em;
          }
        }

        .p-button-text {
          display: none;
        }
      }

      .p-datepicker {
        padding: 15px 0;

        .p-datepicker-header {
          padding-top: 5px;
          padding-bottom: 12px;

          .p-corner-all {
            position: absolute;

            &.p-datepicker-next:before,
            &.p-datepicker-prev:before {
              content: normal;
            }

            &.p-datepicker-prev {
              left: 15px;
            }

            &.p-datepicker-next {
              right: 15px;
            }

            span {
              display: inherit;
            }
          }

          .p-datepicker-title {
            display: flex;
            justify-content: center;
            font-size: 14px;
          }
        }

        .p-datepicker-calendar-container {
          .p-datepicker-calendar {
            position: relative;
            overflow: hidden;

            tr {
              th {
                text-align: center;
              }

              td {
                position: relative;

                a {
                  line-height: 18px;
                }

                .p-state-default {
                  height: $input-height;
                  width: 33px;
                  z-index: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}
