@import 'abstracts';

body .p-button.p-button-label-icon-left .p-button-label, 
body .p-button.p-button-label-icon-right .p-button-label, 
.p-button .p-button-label {
  line-height: normal;
}

.max-content .p-button-text {
  width: max-content;
  padding-left: 1em;
  padding-right: 1em;
}

button.p-button.cap-button {
  font-size: 14px;
  border-radius: 3px;
  padding: 0 15px;
  line-height: 20px;

  &:hover {
    text-decoration: unset !important;
  }

  &.p-button-primary,
  &.primary,
  &.btn-primary {
    background-color: $primary;
    color: $white;
    box-shadow: 0 2px 4px 0 $icon-color;
    border: 2px solid transparent;
  }

  &.secondary {
    background: $white;
    border-color: var(--primary-color);
    color: var(--primary-color);
    box-shadow: 0 2px 4px 0 $button-shadow-color;

    &:not(:disabled) {
      &:hover, &:active, &:focus {
        background: var(--primary-color);
        color: $white;
        outline: none;
        box-shadow: none;
      }
    }

    &:disabled {
      background: var(--third-color);
    }
  }

  &.danger {
    background: $danger;
    border-color: $danger;
    color: $white;
    box-shadow: 0 2px 4px 0 $danger;

    &:hover, &:active, &:focus {
      background: $white;
      color: $danger;
      border-color: $danger;
      box-shadow: 0 2px 4px 0 $danger;
      outline: none;
    }
  }

  &.btn-outline-danger {
    background-color: $white;
    color: $danger;
    border-color: $danger;

    &:hover, &:focus {
      background-color: darken($white, 5);
      box-shadow: 0 2px 4px 0 $danger;
    }
  }

  .p-button-icon-left {
    left: 1em;
  }
}

button, [type='button'], [type='reset'], [type='submit'] {
  appearance: none;
}

.p-splitbutton {
  padding: 0;

  .p-button {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 800;
    box-shadow: 0 2px 4px 0 $button-shadow-color;

    &:focus, &:active {
      outline: none;
      box-shadow: none;
    }
  }

  &.p-buttonset.btn-primary .p-button {
    background-color: var(--primary-color);
  }

  .p-corner-left {
    padding: 6px 6px 6px 25px;
  }

  .p-corner-right {
    padding: 6px 25px 6px 6px;
  }
}

button.p-button.cap-button.filter-button,
button.p-button.cap-button.filter-button:hover {
  border-radius: 20px;
  background: #fff;
  border: 1px solid $border-color;
  color: #666666;
  box-shadow: none;
  height: auto;

  &.danger {
    border: 1px solid $danger;
    color: $danger;

    .fas {
      color: $danger;
      opacity: 0.5;
    }
  }

  &.success {
    border: 1px solid $success;
    color: $success;

    .fas {
      color: $success;
      opacity: 0.5;
    }
  }

  &.primary {
    border: 1px solid $primary;
    color: $primary;

    .fas {
      color: $primary;
      opacity: 0.5;
    }
  }

  .fas {
    color: var(--primary-color);
    opacity: 0.5;
  }
}

.cap-select-button {
  display: flex;

  & > .p-button {
    display: flex;
    flex: 1;
  }
}

.btn-group-override-flex, .row-override-flex {
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;

  @include media-breakpoint-up(sm) {
    display: flex;
  }
}

.override-button-border cap-button button {
  border: none !important;

  @include media-breakpoint-up(sm) {
    border: 2px solid $border-blue !important;
  }
}

.p-button.cap-button.secondary.p-button-icon-only {
  padding: 7px 4px;
  border-width: 1px;
  width: 3em;

  .p-button-icon-left {
    left: auto;
  }
}

app-file-upload[ng-reflect-disabled=true],
cap-button[ng-reflect-disabled=true],
.cap-button:disabled {
  pointer-events: none;
}

body .p-autocomplete .p-button.p-button-icon-only {
  right: 0;
}

body cap-button {
  p-button {
    &.p-disabled {
      background: none;
      opacity: 1;
    }
  }
}