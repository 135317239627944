@import 'abstracts/variables';
@import 'primeng/resources/components/accordion/accordion.css';

body p-accordion {
  .p-accordion {
    .p-accordion-tab {
      .p-accordion-toggle-icon {
        display: none;
      }

      background-color: $white;
      margin-bottom: 0;

      &.p-accordion-tab-active {
        .show-expanded {
          display: inherit;
        }

        .hide-expanded {
          display: none;
        }

        .toggle-button:after {
          content: '\f078';
        }
      }

      &:not(.p-disabled) {
        outline: none;
        border: none;

        &.p-highlight,
        &:not(.p-highlight) {
          &:hover > a,
          > a {
            border: none;
            background-color: $white;
            color: $input-color;
          }

          .p-accordion-header-link,
          &:hover .p-accordion-header-link {
            background-color: $white;
            color: $input-color;
            font-weight: 700;
            font-size: 16px;
            border: none;
            outline: none;
            flex-direction: row-reverse;
          }
        }

        &:not(.p-active) {
          &:hover {
            > a {
              border: none;
              background-color: $white;
            }
          }
        }

        > a,
        .p-accordion-header-link {
          background-color: $white;
          color: $input-color;
          font-weight: 600;
          border: none;
          outline: none;
          flex-direction: row-reverse;

          p-header {
            width: 100%;
          }
        }
      }

      > a {
        border: none;
        padding: 15px 20px;

        .p-accordion-toggle-icon {
          display: none;
          position: absolute;
          top: 50%;
          right: 1em;
          margin-top: -.5em;
          margin-left: -.5em;
          color: var(--primary-color) !important;
          font-size: 15px;
        }

        p-header {
          flex-grow: 1;
        }

        p-header,
        .p-accordion-header-text {
          font-size: 14px;
        }
      }

      .toggle-button {
        align-items: center;
        background-color: $white;
        border: none;
        font-size: 14px;
        color: var(--primary-color);
        padding-right: 20px;
        position: relative;
        text-decoration: underline;

        &:after {
          font-family: 'Font Awesome 5 Pro', serif;
          font-weight: 900;
          content: '\f054';
          position: absolute;
          right: 0;
          top: 2px;
        }
      }

      .show-expanded {
        display: none;
      }

      .subtitle {
        color: $dropdown-trigger-color;
        font-weight: normal;
      }
    }

    .p-accordion-content {
      border: none;
      border-radius: unset;
      padding-top: 0;
      font-size: 14px;
      color: $preview-color;
    }

    p-accordionTab {
      position: relative;
      display: block;
      margin-bottom: 10px;
    }
  }
}

p-accordionTab {
  .inner-accordion-body {
    border-top: 1px solid $border-color;
    padding-top: 15px;
  }
}

.personal-data-accordion {
  margin-top: 1rem;
}
