@layer primeng {
    .p-multiselect {
        display: inline-flex;
        cursor: pointer;
        position: relative;
        user-select: none;
    }

    .p-multiselect-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }

    .p-multiselect-label-container {
        overflow: hidden;
        flex: 1 1 auto;
        cursor: pointer;
        display: flex;
    }

    .p-multiselect-label {
        display: block;
        white-space: nowrap;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .p-multiselect-label-empty {
        overflow: hidden;
        visibility: hidden;
    }

    .p-multiselect-token {
        cursor: default;
        display: inline-flex;
        align-items: center;
        flex: 0 0 auto;
    }

    .p-multiselect-token-icon {
        cursor: pointer;
    }
    .p-multiselect-token-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100px;
    }
    .p-multiselect-items-wrapper {
        overflow: auto;
    }

    .p-multiselect-items {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .p-multiselect-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: normal;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
    }

    .p-multiselect-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .p-multiselect-filter-container {
        position: relative;
        flex: 1 1 auto;
    }

    .p-multiselect-filter-icon {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
    }

    .p-multiselect-filter-container .p-inputtext {
        width: 100%;
    }

    .p-multiselect-close {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        overflow: hidden;
        position: relative;
    }

    .p-fluid .p-multiselect {
        display: flex;
    }

    .p-multiselect-clear-icon {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
        cursor: pointer;
    }

    .p-multiselect-clearable {
        position: relative;
    }
}