// General Colors
$primary: #0160b8;
$success: #4cd964;
$danger: #e3342f;
$white: #ffffff;
$dark-gray: #515151;
$light-gray: #eee;
$uploader-border: #d0d0d0;

// Layout
$body-background: #f9f9f9;

// Table
$table-column-border: #f9f9f9;

// Colors
$border-color: #ddd;
$icon-color: rgba(1, 96, 184, 0.5);
$muted-color: rgba(51, 51, 51, 0.5);
$preview-color: rgba(51, 51, 51, 0.8);
$lighter-gray: #5f6062;
$gray-light: #ddd;
$primary-transparent: rgba(1, 96, 184, 0.5);
$badge-bg-color: #FF3B30;

// Block
$block-center-color: rgba(4, 4, 15, 0.25);
$block-footer-color: #cccccc;

// Sidebar
$sidebar-container-background: rgba(249, 249, 249, 0.75);
$sidebar-item-active-background: rgba(1, 96, 184, 0.03);

// Input
$input-color: #333333;
$input-height: 33px;

// Datepicker
$datepicker-select-color: rgba(1, 96, 184, 0.1);

// Dialog
$dialog-shadow-color: rgba(0, 0, 0, 0.2);

// Dropdown
$dropdown-border-radius: 20px;
$dropdown-trigger-right: 7px;
$dropdown-trigger-color: #5f6062;
$dropdown-shadow-color: rgba(0, 0, 0, 0.1);
$dropdown-text-color: #5f6062;

// Table
$table-border-transparent: rgba(0, 0, 0, 0.15);
$table-font-size: 12px;

// Typography
$font-size-base: 1rem;
$font-family-base: SansSerif, serif;
$font-awesome: "Font Awesome 5 Pro";

// Buttons
$input-btn-padding-y: 0.313rem;
$input-btn-padding-z: 0.625rem;
$button-shadow-color: rgba(1, 96, 184, 0.25);
$btn-padding-y: 4px;
$btn-padding-x: 9px;
$btn-border-radius: 3px;

// Login
$login-background: url(/assets/images/layout/auth/auth-background.png);
$login-logo: url(/assets/images/layout/auth/auth-logo.svg);

// Spinner color and margin
$spin-color: $primary;
$spin-margin: 0 auto;

// Sidebar width
$sidebar-width: 0;

// Boxed layout width
$boxed-width: 1200px;

// Border radius for buttons
$btn-border-radius: 3px;

// Navigation
$nav-bg: #1c1c1c;
$nav-text-color: #c2c2c2;

// Default border
$border-primary: 1px solid $primary;

// Shadows
$shadow-1: rgba(0, 0, 0, 0.1);

// Login
$login-panel: #fff !default;
$login-panel-color: #fff !default;

// Advanced search
$search-result-highlight-color: #f7ef52;

// Border
$border-blue: #155895;

// Column Block
$column-block-color: #333333;

// Timeline
$timeline-color: #6a6b6d;