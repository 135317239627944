@import 'abstracts/variables';
@import 'abstracts/breakpoints';
@import 'bootstrap/scss/functions';
@import 'primeng/resources/components/dropdown/dropdown.css';
@import 'primeng/resources/components/multiselect/multiselect.css';
@import 'primeng/resources/components/inputswitch/inputswitch.css';

body {
  form {
    .form-heading {
      font-size: 16px;
      font-weight: 600;
      padding: 25px 0;
    }

    .row:not(.form-heading) {
      > div:not(:last-child) {
        .form-content {
          border-right: 1px solid $border-color;
          margin-left: -15px;
          margin-right: -15px;
          padding-left: 15px;
          padding-right: 15px;

          @include media-breakpoint-down(md) {
            border-right: none;
          }
        }
      }
    }
  }

  cap-input {
    .form-element .p-inputgroup .p-inputtext {
      font-size: 14px;
      width: 100%;

      &:hover,
      &:focus,
      &:enabled,
      &:enabled:focus:not(.p-state-error),
      &:enabled:hover:not(.p-state-error) {
        font-size: 14px;
        max-height: 33px;
      }
    }

    &.input-round {
      .form-element {
        .p-inputgroup {
          .p-inputgroup-addon {
            font-size: 16px;
            color: $icon-color;
            padding: 0.2em;

            & + input {
              border-radius: $dropdown-border-radius;
              width: 100%;
              padding-top: 6px;
              padding-bottom: 7px;

              &.p-inputtext.content-left,
              &.p-inputtext.content-left:enabled:hover,
              &.p-inputtext.content-left:enabled:focus {
                width: 100%;
                height: $input-height;
                padding-top: 6px;
                padding-bottom: 7px;

                &:hover,
                &:focus,
                &:active {
                  border-radius: $dropdown-border-radius;
                  height: $input-height;
                }
              }
            }
          }
        }
      }
    }

    &.ng-invalid
      .form-element
      .p-inputgroup
      .p-inputgroup-addon
      + input.p-inputtext.content-left {
      border-color: $danger;
    }

    .form-element {
      label {
        font-size: 14px;
        font-weight: 600;
        color: $input-color;
      }

      cap-validator {
        display: none;
      }

      .p-inputgroup {
        position: relative;

        .p-inputgroup-addon {
          /* ICON */
          position: absolute;
          background: none;
          left: 5px;
          height: 100%;
          font-size: 14px;
          color: $muted-color;
          border: none;

          & + input {
            padding-left: 35px;
            color: $input-color;
            font-size: 15px;
            border: 1px solid $border-color;
            font-family: 'Open Sans', serif;

            &.p-inputtext.content-left,
            &.p-inputtext.content-left:enabled:hover,
            &.p-inputtext.content-left:enabled:focus {
              border: 1px solid $border-color;
              padding-left: 35px;
              font-size: 15px;
            }
          }

          .fa-search {
            font-size: 1rem;
            display: flex;
            height: 100%;

            &:before {
              margin: auto;
            }
          }
        }
      }
    }

    input::placeholder {
      color: $dark-gray;
      opacity: 0.7;
    }
  }

  .p-helper-hidden-accessible {
    display: none;
  }

  cap-dropdown,
  cap-multi-select,
  app-range-filter {
    display: inline-block;

    &.btn-round {
      .cap-dropdown.p-dropdown,
      .cap-multi-select.p-multiselect {
        border-radius: $dropdown-border-radius;

        .p-dropdown-label,
        .p-multiselect-label {
          font-weight: 600;
        }
      }
    }

    .form-element {
      display: inline-block;

      .cap-dropdown.p-dropdown,
      .cap-multi-select.p-multiselect {
        border: solid 1px $border-color;
        text-align: center;
        padding-right: 35px;
        padding-left: 11px;
        height: $input-height;
        min-width: unset;

        &:not(.p-state-disabled):hover {
          border: 1px solid var(--primary-color);
          background-color: $white;
          height: $input-height;
          font-size: 14px;

          .p-dropdown-label {
            background-color: transparent;
            height: 100%;
          }

          .p-dropdown-trigger,
          .p-multiselect-trigger {
            background-color: transparent;
            border: none;
            color: $muted-color;
          }

          .p-multiselect-label-container {
            height: 100%;

            .p-multiselect-label {
              height: 100%;
            }
          }
        }

        .p-multiselect-label-container {
          height: 100%;
          padding-right: 20px;
        }

        .p-dropdown-label,
        .p-multiselect-label {
          color: $dropdown-text-color;
          font-size: 14px;
          height: 100%;
          padding: 0 !important;
          margin-bottom: 0;
        }

        .p-dropdown-clear-icon {
          right: 2.4em;
        }

        .p-dropdown-trigger,
        .p-multiselect-trigger {
          background-color: transparent;
          right: $dropdown-trigger-right;
          border-left: none;
          position: absolute;
          top: 50%;
          transform: translate3d(0, -50%, 0);

          span {
            color: $dropdown-trigger-color;
            font-size: 14px;
          }
        }

        .p-dropdown-panel,
        .p-multiselect-panel {
          margin-top: 3px;
          border: none;
          color: $dropdown-text-color;
          font-size: 14px;
          outline: none;
          overflow: hidden;

          .p-dropdown-items,
          .p-multiselect-items {
            margin-bottom: 0;

            .p-dropdown-item,
            .p-multiselect-item {
              border-top: none;

              &:not(&:last-child) {
                border-bottom: 1px solid $border-color;
              }
            }
          }
        }
      }
    }
  }

  .input-filter-wrapper {
    height: 33px;

    i {
      left: 12em !important;
    }
  }

  .input-filter-wrapper,
  .calendar-filter-wrapper,
  .checkbox-filter-wrapper,
  .dropdown-wrapper,
  .multi-select-wrapper,
  .range-filter-wrapper {
    position: relative;

    & > i[class*='fa-'] {
      font-weight: 600;
      font-size: 16px;
      position: absolute;
      right: 3.3em;
      top: 0.5em;
      z-index: 1;
      color: $icon-color;
      cursor: pointer;

      &.clear-filter-icon {
        color: $danger;
        margin-right: 5px;
      }
    }

    cap-dropdown .form-element .p-dropdown,
    cap-multi-select .form-element .cap-multi-select.p-multiselect,
    app-range-filter .range-filter-button,
    cap-input .form-element .p-inputgroup .p-inputtext {
      font-size: 14px;
      font-weight: 600;
      padding-left: 3em;
      height: $input-height;

      &:hover,
      &:focus,
      &:active,
      &:not(.p-state-disabled):focus &:not(.p-state-disabled):hover {
        border-color: var(--primary-color);
        height: $input-height;
        font-size: 14px;
      }
    }

    cap-calendar {
      &.btn-round p-calendar input.p-inputtext {
        border-radius: $dropdown-border-radius;
      }

      p-calendar input.p-inputtext {
        padding: 7px 20px 7px 40px !important;
        font-size: 15px !important;
        font-weight: 600;
      }
    }
  }

  .checkbox-filter-wrapper {
    padding-top: 7px;

    .p-chkbox-label {
      vertical-align: unset;
      font-weight: 600;
      font-size: 14px;
    }
  }

  cap-select-button {
    .p-button {
      display: flex;

      .p-button-text {
        margin: auto;
      }
    }
  }

  cap-multi-select {
    .cap-multi-select,
    .cap-multi-select:hover,
    .p-multiselect-open .p-multiselect-label,
    .p-multiselect-open:hover .p-multiselect-label,
    .cap-multi-select.p-multiselect .p-multiselect-label,
    .cap-multi-select.p-multiselect .p-multiselect-label:hover {
      display: flex;
      justify-content: flex-start !important;
      text-align: left;
      height: 100%;
    }

    .form-element {
      .cap-multi-select.p-multiselect {
        padding-top: 5px;
        padding-bottom: 5px;

        .p-multiselect-header {
          display: none;
        }

        .p-multiselect-panel {
          .p-multiselect-items {
            .p-multiselect-item {
              &.p-multiselect-item.p-state-highlight {
                background-color: transparent;
                color: inherit;
              }

              > span {
                margin-left: 5px;
              }
            }
          }
        }
      }

      .p-inputwrapper-filled {
        display: flex;
      }
    }
  }

  .form-group {
    label {
      font-size: 14px;
      font-weight: 600;
      margin-left: 0;
      width: 100%;
    }

    .ng-valid {
      .validator {
        display: none;
      }
    }

    .help-block {
      color: $danger;
    }

    .validator:not(.show) {
      display: none;
    }

    cap-dropdown,
    cap-multi-select {
      display: block;

      &.btn-round {
        .p-dropdown .p-dropdown-trigger,
        .p-multiselect .p-multiselect-trigger {
          border-radius: $dropdown-border-radius;

          .p-dropdown-label,
          .p-multiselect-label-container .p-multiselect-label {
            font-weight: 600;
          }
        }
      }

      .form-element {
        display: block;
      }

      .p-dropdown,
      .p-multiselect {
        border-radius: 3px;
        text-align: left;
        width: 100%;

        .p-dropdown-label,
        .p-multiselect-label-container .p-multiselect-label {
          text-align: left;
          padding-left: 0;
          max-width: inherit;
          min-height: 21px;
        }

        .p-dropdown-trigger,
        .p-multiselect-trigger {
          border-left: 1px solid $border-color;
          right: 0;
        }
      }
    }

    cap-multi-select {
      .p-multiselect {
        padding: 7px 10px;
      }
    }

    cap-input {
      .form-element {
        .p-inputgroup {
          .p-inputgroup-addon {
            color: var(--primary-color);
          }
        }
      }
    }

    .form-switch-box {
      cap-input-switch {
        margin-right: 15px;
        float: left;
      }

      span {
        font-size: 14px;
      }
    }
  }

  cap-textarea {
    .cap-textarea {
      resize: none !important;

      &::placeholder {
        color: $gray-500;
      }
    }
  }

  cap-input-switch {
    .form-element {
      p-inputswitch {
        div.p-inputswitch {
          background-color: transparent;
          box-shadow: none;

          &.p-inputswitch-checked:not(.p-state-disabled),
          &.p-inputswitch-checked:not(.p-state-disabled):hover {
            .p-inputswitch-slider {
              background-color: var(--success-color);
            }
          }
        }
      }
    }
  }

  cap-autocomplete {
    .p-autocomplete {
      width: 100%;

      .p-autocomplete-input {
        padding: 10px;
        font-size: 14px;
        width: calc(100% - 1.9em);
      }

      &.p-autocomplete-multiple .p-autocomplete-multiple-container {
        width: 100%;
        border-color: $border-color;

        .p-autocomplete-input-token input {
          border: none;
          padding: 5px;
          height: $input-height;
          width: 100%;
        }
      }
    }
  }

  formly-form {
    cap-wrapper-form-field {
      .form-group {
        width: 100%;

        cap-input-formly,
        cap-formly-dropdown,
        cap-multi-select-type {
          width: 100%;
        }

        lib-checkbox-formly,
        cap-input-switch-formly {
          float: left;

          cap-checkbox {
            .form-element {
              padding: 0;
            }
          }
        }

        cap-input-switch-formly {
          margin-right: 15px;
          margin-left: 10px;
        }

        .form-text {
          margin: 0;
          font-weight: 600;
        }
      }
    }
  }
}

app-horizontal-input .required {
  color: $danger;
}

form .validation-message {
  color: $danger;
  font-size: 0.8em;
  display: block;

  &.only-on-submit {
    display: none;
  }
}

form.ng-submitted {
  cap-validator {
    display: none;
  }

  .validation-message {
    display: block;
  }
}

body cap-dropdown .form-element .cap-dropdown.p-dropdown .p-dropdown-label,
body cap-dropdown .form-element .cap-dropdown.p-dropdown .p-multiselect-label,
body cap-dropdown .form-element .cap-multi-select.p-multiselect .p-dropdown-label,
body cap-dropdown .form-element .cap-multi-select.p-multiselect .p-multiselect-label,
body cap-multi-select .form-element .cap-dropdown.p-dropdown .p-dropdown-label,
body cap-multi-select .form-element .cap-dropdown.p-dropdown .p-multiselect-label,
body cap-multi-select .form-element .cap-multi-select.p-multiselect .p-dropdown-label,
body cap-multi-select .form-element .cap-multi-select.p-multiselect .p-multiselect-label,
body app-range-filter .form-element .cap-dropdown.p-dropdown .p-dropdown-label,
body app-range-filter .form-element .cap-dropdown.p-dropdown .p-multiselect-label,
body app-range-filter .form-element .cap-multi-select.p-multiselect .p-dropdown-label,
body app-range-filter .form-element .cap-multi-select.p-multiselect .p-multiselect-label {
  text-align: left;
  background: transparent;
}

.horizontal-input-with-icon cap-input {
  width: 95%;
}

.form-list-filter-field {
  cap-validator {
    display: none;
  }
}

form.wizard-form {
  &.ng-submitted {
    cap-input.ng-invalid input {
      border: 1px solid $danger;
    }

    cap-dropdown.ng-invalid .p-dropdown {
      border: 1px solid $danger;
    }

    cap-calendar.ng-invalid .p-calendar input {
      border: 1px solid $danger;
    }
  }
}

body .p-inputgroup input.p-inputtext {
  width: 100%;
}

body .cap-dropdown-panel.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  text-align: left;
}

.pointer-events-none {
  pointer-events: none;
}

.table-filters {
  &.cap-table-filter {
    .row {
      [class*='col-'] {
        position: relative;
      }
    }
  }
}

body app-client-projects {
  .sending-type-cell {
    cap-dropdown {
      cap-validator {
        display: none;
      }
    }
  }
}