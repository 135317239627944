@mixin all-directions($short, $long, $size, $type) {
  .#{$short}r-#{$size} {
    #{$long}-right: #{$size}#{$type} !important;
  }

  .#{$short}l-#{$size} {
    #{$long}-left: #{$size}#{$type} !important;
  }

  .#{$short}t-#{$size} {
    #{$long}-top: #{$size}#{$type} !important;
  }

  .#{$short}b-#{$size} {
    #{$long}-bottom: #{$size}#{$type} !important;
  }

  .#{$short}x-#{$size} {
    #{$long}-left: #{$size}#{$type};
    #{$long}-right: #{$size}#{$type} !important;
  }

  .#{$short}y-#{$size} {
    #{$long}-top: #{$size}#{$type} !important;
    #{$long}-bottom: #{$size}#{$type} !important;
  }
}

@mixin setBottomGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-bottom: $value;

    &:last-child {
      margin-bottom: $lastGutter;
    }
  }
}

@mixin setObjectFit($type) {
  width: 100%;
  height: 100%;
  object-fit: $type;
}

@mixin checkBoxIcon() {
  font-family: $font-awesome;
  font-weight: 900;
  font-size: 0.8125rem;
  height: auto;
  width: auto;
  line-height: 1;
  justify-content: center;
  align-items: center;

  &:before {
    content: '\f0c8';
    color: var(--cap-primary-color);
    margin-top: 1px;
  }

  svg {
    display: none;
  }
}
