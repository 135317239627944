@import 'abstracts/variables';

$name: 'basic-block';

.#{$name} {
  &.header-white {
    .#{$name}__header {
      background-color: $white;
    }
  }

  &.body-white {
    .#{$name}__body {
      background-color: $white;
    }
  }

  &.header-white.body-white {
    .#{$name}__header:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 4%;
      width: 92%;
      height: 1px;
      background: $border-color;
    }
  }

  .#{$name}__header, .#{$name}__body {
    padding: 15px 20px;
    font-size: 14px;
  }

  .#{$name}__header {
    position: relative;
    font-weight: bolder;
    color: $input-color;
  }
}
