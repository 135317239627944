@import 'abstracts/variables';
@import 'abstracts/breakpoints';

emc-role-list, emc-user-detail {
  p-table {
    tr.filters-row {
      display: none;
    }
  }

  .ibox-content {
    form {
      padding-top: 15px;
    }
  }
}

emc-user-detail {
  .ibox {
    .ibox-content {
      flex: 100%;
      max-width: 100%;

      @include media-breakpoint-up(lg) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include media-breakpoint-up(xl) {
        flex: 0 0 40%;
        max-width: 40%;
      }

      cap-button:not(:last-child) {
        button {
          margin-right: 15px;
        }
      }
    }
  }
}
