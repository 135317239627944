@import 'abstracts/variables';

body .notification-badge {
  background: $badge-bg-color;
  color: $white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px !important;
  padding: 6px;
  min-width: 20px;
  height: 20px;
  border-radius: 20px;
  border: solid 1px #f7fafd;
}

body nav .notification-badge--position {
  position: absolute;
  top: -5px;
  left: calc(50%);
  right: unset;
}

@media (max-width: 1200px) {
  body nav .notification-badge--position {
    position: absolute;
    top: -5px;
    right: 0;
    left: unset;
  }
}







