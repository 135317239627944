@import "abstracts/variables";

.block-preview {
  border-radius: 3px;

  &.block-preview__white {
    background-color: $white;

    .block-preview__heading {
      border-bottom: 1px solid $border-color;
    }
  }

  .block-preview__heading {
    font-size: 14px;
    font-weight: 800;
    color: $input-color;
    margin-bottom: 15px;
    background-color: $white;
    padding: 15px 20px;
  }

  .block-preview__body {
    color: $preview-color;

    > .row {
      padding: 0 0 15px;
    }
  }
}
