@import 'abstracts/variables';

cap-login .login-wrapper .content-right .login-form {
  .title {
    margin-bottom: 1rem;

    .text {
      font-size: 20px;
    }
  }

  .subtitle {
    padding: 0;
    margin-bottom: 1rem;
  }

  .flex-buttons,
  cap-password cap-input .form-element {
    margin-top: 1rem;
  }

  .login-separator {
    color: $preview-color;
  }

  .login-actions {
    display: flex;
    align-items: center;

    .login-link {
      text-decoration: none;
      color: $primary;
      font-weight: bold;
    }
  }
}
